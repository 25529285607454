import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <div
      css={`
        position: fixed;
        font-family: "Kinder";
        font-size: 40px;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
      `}
    >
      Cargando vista previa...
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO,
  accessToken: process.env.GATSBY_PRISMIC_TOKEN,
  linkResolver: ({ node }) => (doc) => {
    if (node.type === "case") {
      return `/case/${node.uid}`;
    } else if (node.type === "home") {
      return "/";
    } else if (node.type === "contact") {
      return "/contact";
    }
  },
});
